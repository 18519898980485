import React, { useEffect, useMemo } from "react";
import './styles.scss';
const navIcons = [
    { name: 'video_camera', size: 24 },
    { name: 'mic', size: 24 },
    { name: 'rotate', size: 24 },
    { name: 'telephone', size: 32 },
];
const Frame = ({ deal }) => {
    const configRef = useMemo(() => ([
        ["host-name", "https://kenes.1414.kz"],
        ["domain", "enis.bankffin.kz"],
        ["request-id", deal === null || deal === void 0 ? void 0 : deal.enis_id],
        ["request-call-feedback", '0'],
        ["lang", (deal === null || deal === void 0 ? void 0 : deal.locale) || "ru"],
        ["target-operator-id", deal === null || deal === void 0 ? void 0 : deal.notary_enis_id],
        ["topic", "enis-bankffin-test"],
    ]), [deal === null || deal === void 0 ? void 0 : deal.enis_id]);
    const connect = () => {
        const videoCallElem = document.createElement("wc-video-call"); // Обязательно название должно быть 'wc-video-call'
        configRef.forEach(([key, val]) => videoCallElem.setAttribute(key, val));
        videoCallElem.addEventListener("wc", (event) => {
            var _a, _b;
            if ((event === null || event === void 0 ? void 0 : event.detail) && ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.length)) {
                console.log("event", (_b = event.detail) === null || _b === void 0 ? void 0 : _b[0]);
            }
        });
        document.getElementById("video-call-wrapper").appendChild(videoCallElem);
    };
    useEffect(() => {
        connect();
    }, []);
    return React.createElement("div", { className: "frame", id: "video-call-wrapper" });
};
export default Frame;
